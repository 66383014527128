
import { timerTime } from "@/constants/general";
import { useAlertContext } from "@/contexts/AlertContext";
import { getRefresh } from "@/lib/Apis";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";


export const useApiError = () => {
    const [timeLeft, setTimeLeft] = useState<number>(timerTime * 60);
    const { data: session, update }: any = useSession();
    const { setShowModalRefresh } = useAlertContext();
    const [loading, setLoading] = useState(false);
    const router = useRouter();
    const modalTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const toastDisplayedRef = useRef(false);

    // Función para mostrar el modal
    const triggerModal = () => {
        setShowModalRefresh(true);

        // Iniciar temporizador de 2 minutos (120 segundos) para cerrar sesión si no hay interacción
        modalTimeoutRef.current = setTimeout(() => {
            handleLogout(); // Cerrar sesión después de 2 minutos si no interactúa
        }, 2 * 60 * 1000); // 2 minutos
    };

    useEffect(() => {
        if (session) {
            // const currentTimeLeft = localStorage.getItem("sessionTimer");
            const storedTime = localStorage.getItem("sessionTimer");
            if (storedTime) {
                setTimeLeft(Number(storedTime));
            } else if (session) {
                localStorage.setItem("sessionTimer", String(timeLeft));
            }

            // Temporizador para descontar cada segundo
            const timerInterval = setInterval(() => {
                if (storedTime) {
                    const newTime = Number(storedTime) - 1;
                    setTimeLeft(newTime);
                    localStorage.setItem("sessionTimer", String(storedTime));

                    if (newTime <= 0) {
                        clearInterval(timerInterval);
                        triggerModal(); // Mostrar el modal cuando el tiempo llegue a 0
                    }
                }
            }, 1000); // Actualiza cada segundo

            return () => clearInterval(timerInterval); // Limpiar el intervalo al desmontar
        }
    }, [session]);

    const resetTimer = () => {
        setTimeLeft(timerTime * 60);
        localStorage.setItem("sessionTimer", String(timerTime * 60));
        setShowModalRefresh(false);
        if (modalTimeoutRef.current) {
            clearTimeout(modalTimeoutRef.current);
            modalTimeoutRef.current = null;
        }
    };

    const getApiError = async (error: any) => {
        if (toastDisplayedRef.current) return;
        toastDisplayedRef.current = true; // Marca como mostrado el toast
        if (error && typeof error === 'string' && session) {
            const errorObject = JSON.parse(error);
            switch (errorObject?.status) {
                case 401:
                    // setShowModalRefresh(true);
                    handleLogout();
                    break;
                default:
                    return toastError(errorObject?.message);
            }
        }
    }

    const toastError = (message: string) => {
        toast.error(message, {
            position: "top-right",
        });
        setTimeout(() => {
            toastDisplayedRef.current = false;
        }, 5000);
    }

    const handleLogout = () => {
        localStorage.removeItem("sessionTimer");
        sessionStorage.removeItem("hasSentGeneralNotifications")
        sessionStorage.removeItem("hasSentEventNotifications")
        sessionStorage.removeItem("hasSentPosNotifications")
        sessionStorage.removeItem("hasSentApproveEventNotifications")
        sessionStorage.removeItem("hasSentApprovePosNotifications")
        setShowModalRefresh(false);
        signOut({ callbackUrl: "/auth/login" });
    };

    const getRefreshApi = async () => {
        try {
            setLoading(true);
            const data = await getRefresh(session?.user?.refreshToken);
            await update({
                ...session,
                user: {
                    ...session.user,
                    accessToken: data.access_token,
                },
            });
            resetTimer();
            setShowModalRefresh(false);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            toast.error(error.message, {
                position: "top-right",
            });
        }
    };


    return { getApiError, getRefreshApi, handleLogout, loading }
}