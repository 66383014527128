
export const colorAppointmentsByStatus = {
    scheduled_call: "#FFCC00",
    scheduled_visit: "#F58A07",
    not_interested: "#FF274E",
    not_sold: "#FF274E",
    sold: "#88C946"
}

export const eventsType = {
    pos: "POS",
    event: "EVE"
}

export const statusRequestEvent = {
    pending: "PEN",
    approved: "APP",
    declined: "DEC",
}

export const statusEvent = {
    assigned: "ASG",
    unassigned: "UNA",
    partial: "PAR",
    assigned_company: "ASC",
}

//---------------------------------------------------------------//

export const timerTime = process.env.NEXT_PUBLIC_MYBPOINTER_TIMER ? parseInt(process.env.NEXT_PUBLIC_MYBPOINTER_TIMER) : 25

export const category_type = {
    admin: "H1Sw4",
    gerent_sales: "l1yV0",
    vendedor: "cFBXx",
    director_sales: "YQKMP",
    director_marketing: "4fpyF",
    director_department: "A2MTG",
    supervisor: "cDLch",
    promotor: "twVP4"
}

export const sourceBrcTypeName = {
    walkIn: "WAL",
    pos: "POS",
    event: "EVE",
    medics: "MED",
    home: "HOM"
}

export const saleTypeTypeName = {
    callIn: "CAL",
    walkIn: "WAL",
    inBound: "INB",
    scheduled: "SCH",
}

export const saleStatusTypeName = {
    scheduledCall: "SCC",
    scheduledVisit: "SCV",
    notInterested: "NOI",
    notSold: "NOS",
    sold: "SOL"
}

export const permisionsByRoles = {
    location: "LOCMNG",
    users: "USRMNG",
    pos_management: "POSMNG",
    event_management: "EVEMNG",
    pos_request: "POSREQ",
    event_request: "EVEREQ",
    brc_management: "BRCMNG",
    companies_managament: "COMMNG",
    calendar_managament: "CALMNG",
    roles_managament: "ROLMNG",
    zones_managament: "ZONMNG",
    medic_management: "MEDMNG",
    mydoctor_management: "MYDMNG",
    goals_management: "GOAMNG",
    myhome_management: "HOMMNG",
    product_management: "PRDMNG",
    materials_management: "MATMNG",
}

export const allowedParams = ["view", "assign", "assignCompany", "approve", "update"];
export const protectedRoutes = [
    {
        path: '/settings/locations',
        requiredPermission: [permisionsByRoles.location],
    },
    {
        path: '/pos',
        requiredPermission: [permisionsByRoles.pos_management],
    },
    {
        path: '/pos/request',
        requiredPermission: [permisionsByRoles.pos_request],
    },
    // {
    //     path: '/pos/pending',
    //     requiredPermission: [permisionsByRoles.pos_request],
    //     // requiredSubPermission: ['SHWREQ']
    // },
    // {
    //     path: '/pos/my-pos',
    //     requiredPermission: [permisionsByRoles.pos_management],
    // },
    {
        path: '/settings/team',
        requiredPermission: [permisionsByRoles.users],
    },
    {
        path: '/settings/permissions',
        requiredPermission: [permisionsByRoles.roles_managament]
    },
    {
        path: '/event',
        requiredPermission: [permisionsByRoles.event_management]
    },
    {
        path: '/event/request',
        requiredPermission: [permisionsByRoles.event_request]
    },
    // {
    //     path: '/event/my-events',
    //     requiredPermission: [permisionsByRoles.pos_management],
    // },
    {
        path: '/event/pending',
        requiredPermission: [permisionsByRoles.event_request],
        // requiredSubPermission: ['SHWREQ']
    },
    {
        path: '/brc',
        requiredPermission: [permisionsByRoles.brc_management]
    },
    {
        path: '/settings/companies',
        requiredPermission: [permisionsByRoles.companies_managament]
    },
    {
        path: '/settings/companies/add',
        requiredPermission: [permisionsByRoles.companies_managament],
        requiredSubPermission: ['CRE']
    },
    {
        path: '/settings/companies/edit',
        requiredPermission: [permisionsByRoles.companies_managament],
        requiredSubPermission: ['EDI']
    },
    {
        path: '/settings/zones',
        requiredPermission: [permisionsByRoles.zones_managament]
    },
    {
        path: '/settings/goals',
        requiredPermission: [category_type.admin, category_type.gerent_sales],
    },
    {
        path: '/settings/products',
        requiredPermission: [permisionsByRoles.product_management],
    },
    // {
    //     path: '/brc/create',
    //     requiredPermission: [permisionsByRoles.brc_management]
    // },
    // Añade más rutas y permisos según sea necesario
    {
        path: '/settings/profile',
        requiredPermission: [permisionsByRoles.users],
    },
];

export const GoogleMapsKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "AIzaSyCwFjBDb2CCiKiKANd3OS8Lwvr2ihHhwlA";

export const permissionsCompany = [
    {
        "name": "Users management",
        "type_permission": "USRMNG",
        "subpermissions": [
            {
                "name": "Create user",
                "type_subpermission": "CRE",
                "active": true
            },
            {
                "name": "Edit user",
                "type_subpermission": "EDI",
                "active": true
            },
            {
                "name": "Delete user",
                "type_subpermission": "DEL",
                "active": true
            },
            {
                "name": "Change status (active/inactive)",
                "type_subpermission": "CHS",
                "active": true
            }
        ]
    },
    {
        "name": "Location management",
        "type_permission": "LOCMNG",
        "subpermissions": [
            {
                "name": "Create location",
                "type_subpermission": "CRE",
                "active": true
            },
            {
                "name": "Edit location",
                "type_subpermission": "EDI",
                "active": true
            }
        ]
    },
    {
        "name": "POS management",
        "type_permission": "POSMNG",
        "subpermissions": [
            {
                "name": "Assign POS to myself",
                "type_subpermission": "ASSMYS",
                "active": true
            },
            {
                "name": "Unassign POS to myself",
                "type_subpermission": "UNAMYS",
                "active": true
            },
            {
                "name": "Assign POS to role",
                "type_subpermission": "ASSROL",
                "active": true
            },
            {
                "name": "Unassign POS to role",
                "type_subpermission": "UNAROL",
                "active": true
            },
            {
                "name": "Show only assigned POS",
                "type_subpermission": "SHWASS",
                "active": true
            },
            {
                "name": "Check-in / Check-out",
                "type_subpermission": "CHK",
                "active": true
            }
        ]
    },
    {
        "name": "Event management",
        "type_permission": "EVEMNG",
        "subpermissions": [
            {
                "name": "Assign event to myself",
                "type_subpermission": "ASSMYS",
                "active": true
            },
            {
                "name": "Unassign event to myself",
                "type_subpermission": "UNAMYS",
                "active": true
            },
            {
                "name": "Assign event to role",
                "type_subpermission": "ASSROL",
                "active": true
            },
            {
                "name": "Unassign event to role",
                "type_subpermission": "UNAROL",
                "active": true
            },
            {
                "name": "Show only assigned POS",
                "type_subpermission": "SHWASS",
                "active": true
            },
            {
                "name": "Check-in / Check-out",
                "type_subpermission": "CHK",
                "active": true
            }
        ]
    },
    {
        "name": "POS request",
        "type_permission": "POSREQ",
        "subpermissions": [
            {
                "name": "Request POS creation",
                "type_subpermission": "CREREQ",
                "active": true
            },
            {
                "name": "Edit POS request",
                "type_subpermission": "EDIREQ",
                "active": true
            },
            {
                "name": "Delete POS request",
                "type_subpermission": "DELREQ",
                "active": true
            },
            {
                "name": "Show pending POS requests",
                "type_subpermission": "SHWREQ",
                "active": true
            },
            {
                "name": "Request POS deletion",
                "type_subpermission": "DELPOS",
                "active": true
            },
            {
                "name": "View amount contributed by Humana",
                "type_subpermission": "VAMOUN",
                "active": true
            }
        ]
    },
    {
        "name": "Event request",
        "type_permission": "EVEREQ",
        "subpermissions": [
            {
                "name": "Request event creation",
                "type_subpermission": "CREREQ",
                "active": true
            },
            {
                "name": "Edit event request",
                "type_subpermission": "EDIREQ",
                "active": true
            },
            {
                "name": "Delete event request",
                "type_subpermission": "DELREQ",
                "active": true
            },
            {
                "name": "Show pending event requests",
                "type_subpermission": "SHWREQ",
                "active": true
            },
            {
                "name": "Request event deletion",
                "type_subpermission": "DELEVE",
                "active": true
            },
            {
                "name": "View amount contributed by Humana",
                "type_subpermission": "VAMOUN",
                "active": true
            }
        ]
    },
    {
        "name": "BRC management",
        "type_permission": "BRCMNG",
        "subpermissions": [
            {
                "name": "Create BRC",
                "type_subpermission": "CRE",
                "active": true
            },
            {
                "name": "Edit BRC",
                "type_subpermission": "EDI",
                "active": true
            },
            {
                "name": "Send request to a client",
                "type_subpermission": "SNDREQ",
                "active": true
            },
            {
                "name": "Manage sale status",
                "type_subpermission": "MNGSAL",
                "active": true
            }
        ]
    },
    {
        "name": "Calendar management",
        "type_permission": "CALMNG",
        "subpermissions": [
            {
                "name": "Show admin calendar",
                "type_subpermission": "SHW",
                "active": true
            }
        ]
    },
    {
        "name": "Company management",
        "type_permission": "COMMNG",
        "subpermissions": [
            {
                "name": "Edit company",
                "type_subpermission": "EDI",
                "active": true
            },
            {
                "name": "Assign company director",
                "type_subpermission": "ASSDIR",
                "active": true
            },
            {
                "name": "Edit company director",
                "type_subpermission": "EDIDIR",
                "active": true
            }
        ]
    },
    {
        "name": "Role management",
        "type_permission": "ROLMNG",
        "subpermissions": [
            {
                "name": "Create role",
                "type_subpermission": "CRE",
                "active": true
            },
            {
                "name": "Edit role",
                "type_subpermission": "EDI",
                "active": true
            },
            {
                "name": "Delete role",
                "type_subpermission": "DEL",
                "active": true
            }
        ]
    },
    {
        "name": "Zone management",
        "type_permission": "ZONMNG",
        "subpermissions": [
            {
                "name": "Create zone",
                "type_subpermission": "CRE",
                "active": true
            },
            {
                "name": "Edit zone",
                "type_subpermission": "EDI",
                "active": true
            },
            {
                "name": "Delete zone",
                "type_subpermission": "DEL",
                "active": true
            }
        ]
    }, {
        "name": "Medics management",
        "type_permission": "MEDMNG",
        "subpermissions": [
            {
                "name": "Create Medic",
                "type_subpermission": "CRE",
                "active": false
            },
            {
                "name": "Edit Medic",
                "type_subpermission": "EDI",
                "active": false
            },
            {
                "name": "Delete Medic",
                "type_subpermission": "DEL",
                "active": false
            }
        ]
    },
    {
        "name": "MyHome management",
        "type_permission": "HOMMNG",
        "subpermissions": [
            {
                "name": "Create MyHome",
                "type_subpermission": "CRE",
                "active": false
            },
            {
                "name": "Edit MyHome",
                "type_subpermission": "EDI",
                "active": false
            },
            {
                "name": "Delete MyHome",
                "type_subpermission": "DEL",
                "active": false
            }
        ]
    }
    // {
    //     "name": "Goal management",
    //     "type_permission": "GOAMNG",
    //     "subpermissions": [
    //         {
    //             "name": "Show monthly goal",
    //             "type_subpermission": "SHWMGO",
    //             "active": false
    //         },
    //         {
    //             "name": "Show monthly group goal",
    //             "type_subpermission": "SHWMGG",
    //             "active": false
    //         },
    //         {
    //             "name": "Show AEP",
    //             "type_subpermission": "SHWAEP",
    //             "active": false
    //         },
    //         {
    //             "name": "Show OEP",
    //             "type_subpermission": "SHWOEP",
    //             "active": false
    //         },
    //         {
    //             "name": "Show ROY",
    //             "type_subpermission": "SHWROY",
    //             "active": false
    //         }
    //     ]
    // }
]